<template>
    <div class="life">
        <div class="life-list">
            <p>外卖红包天天领</p>
            <img @click="golink(1)" src="https://ppyos.xijiuyou.com/202307/bd-meituan.png" alt="" />
            <img  @click="golink(2)" src="https://ppyos.xijiuyou.com/202307/bd-elm.png" alt="" />
            <img  @click="golink(3)" src="https://ppyos.xijiuyou.com/202307/bd-kfc.png" alt="" />
            <img  @click="golink(4)" src="https://ppyos.xijiuyou.com/202307/dd-star.png" alt="" />
        </div>
        <div class="life-list">
            <p>出行福利</p>
            <img  @click="golink(6)" src="https://ppyos.xijiuyou.com/202307/bd-didi.png" alt="" />
            <img  @click="golink(5)" src="https://ppyos.xijiuyou.com/202307/dd-luck.png" alt="" />
        </div>
        <div class="life-list">
            <p>电影票</p>
            <img  @click="golink(7)" src="https://ppyos.xijiuyou.com/202307/bd-film.png" alt="" />
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    mounted(){
        try {
            window.android.trackEvent(`LocalCoupons_main_open`,"");
        } catch (err) {
            console.log(err);
        }
    },
    methods:{
        golink(index){
            if(index ===2){
                window.android.startMiniProgram("gh_6506303a12bb","commercialize/pages/taoke-guide/index?scene=8efe10fefb0c447ca042e555f0d17740","",0)
            }else if(index ===1){
                window.android.startMiniProgram("gh_870576f3c6f9","/index/pages/h5/h5?weburl=https%3A%2F%2Fclick.meituan.com%2Ft%3Ft%3D1%26c%3D2%26p%3DirupEL5zT_kT&lch=cps:waimai:5:0600c32a22c501c5a1cbd9afde900909:paopaoyu:33:376099&f_token=1&f_userId=1","",0)
            }else if(index ===3){
                window.android.Browser("https://5kma.cn/vPGb4");
            }else if(index ===4){
                window.android.Browser("https://kzurl17.cn/vPx8l");
            }else if(index ===5){
                window.android.startMiniProgram("gh_22134e59195e","pages/single-page/activitySharingCommon/index?scene=s.t3go.cn/JJDsMihy&sourceId=93563__93563__1002","",0)
            }else if(index ===6){
                window.android.startMiniProgram("gh_7a5c4141778f","/pages/index/index?scene=ekp1r50&source_id=9a5d6fc5379ce4d2a3cb","",0)
            }else if(index ===7){
                window.android.Browser("https://kzurl10.cn/vPHSP");
            }
        },
    }
}
</script>
<style scoped lang='less'>
.life{
    padding: 12px 10px;
    box-sizing: border-box;
    .life-list{
        padding: 16px 16px 6px 16px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: AlibabaPuHuiTiB;
        color: #444444;
        font-weight: bold;
        p{
            margin-bottom: 16px;
        }
        img{
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
</style>